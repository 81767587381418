import { Controller } from "stimulus";
import { INPUT_ERROR_CSS, INPUT_VALID_CSS } from '../style_vars.js';
import $ from 'jquery';

const jquery = $;

export default class extends Controller {
  static targets = [ "topicsList" ];

  connect () {
    this.topicMaxLen = +this.element.getAttribute('data-topic-max-len');
    this.validate();
    this.element.addEventListener('keyup', () => {
      this.validate();
    });
  }

  removeItem (event) {
    const $list = $(this.topicsListTarget);
    if ($list.find('li').length === 1) {
      return
    }
    const $element = $(event.currentTarget);
    const $li = $element.closest('li');
    $li.remove();
  }

  addItem (event) {
    const $ul = $(this.topicsListTarget);
    const $li = $ul.find('li').first();
    const $cloned = $li.clone();
    $cloned.find('input').val('');
    $ul.append($cloned);
  }

  moveUp (event) {
    const $li = $(event.target).parents('li');
    const $liAbove = $li.prev();
    if ($liAbove) {
      $liAbove.insertAfter($li);
    }
  }

  moveDown (event) {
    const $li = $(event.target).parents('li');
    const $liBelow = $li.next();
    if ($liBelow) {
      $liBelow.insertBefore($li);
    }
  }

  validate () {
    const $list = $(this.topicsListTarget);

    $list.find('li').each((idx, el) => {
      const $input = $(el).find("input[type='text']");
      const val = $input.val();
      if (val && (val.length > this.topicMaxLen)) {
        $input
          .removeClass(INPUT_VALID_CSS)
          .addClass(INPUT_ERROR_CSS);
      } else {
        $input
          .removeClass(INPUT_ERROR_CSS)
          .addClass(INPUT_VALID_CSS);
      }
    });
  }
}
