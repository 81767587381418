import { Controller } from "stimulus";
import shareViaEmail from "../share_via_email";
import copy from 'copy-to-clipboard';

export default class extends Controller {
  static targets = [ "embedCode" ];

  connect() {
    this.attrs = JSON.parse(this.element.getAttribute('data-attrs'));
    this.updatePreview();
  }

  embedLink () {
    const { shareUrl } = this.attrs;
    const url = new URL(shareUrl);
    url.pathname = `${url.pathname}/embed`;
    return url.href;
  }

  shareLink () {
    const { shareUrl } = this.attrs;
    return shareUrl;
  }

  copyLink () {
    const link = this.shareLink();
    copy(link);
    Flash.notice('Share URL copied to clipboard');
  }

  copyEmailEmbed () {
    shareViaEmail({
      viewVideoLink: this.shareLink(),
      gifUrl: this.attrs.gifUrl,
    });
    Flash.notice('Copied to clipboard');
  }

  copyIframeCode (evt) {
    const copyEl = evt.target;
    copy(this.iframeCode());
    copyEl.__savedHtml = copyEl.__savedHtml || copyEl.innerHTML;
    copyEl.innerHTML = 'copied to clipboard';
    setTimeout(() => {
      copyEl.innerHTML = copyEl.__savedHtml;
    }, 2000);
  }

  iframeCode () {
    const iframeCode = `<iframe height="600" width="300" src="${this.embedLink()}" frameborder="0" marginheight="0" marginwidth="0" allowtransparency="true"></iframe>`;
    return iframeCode;
  }

  updatePreview () {
    this.embedCodeTarget.innerText = this.iframeCode();
  }
}
