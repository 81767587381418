import { Controller } from "stimulus";
import copy from 'copy-to-clipboard';

export default class extends Controller {
  static targets = ['source'];

  connect() {
    this.element.addEventListener('click', () => {
      const a = this.element.querySelector('a');
      console.log('debug a', a.getAttribute('data-value'));
      const url = a.getAttribute('data-value');
      copy(url);
      Flash.notice('Url copied')
    });
  }
}
