import { Controller } from "stimulus";
import { useClickOutside } from 'stimulus-use';
import $ from 'jquery';

const jquery = $;
window.jquery = jquery;

export default class extends Controller {
  static targets = [ "videoList", "videoOrderList", "clickOutside" ];

  connect() {
    useClickOutside(this, { element: this.clickOutsideTarget });
    this.updateOrders();
  }

  openVideosList () {
    if (this.videoListTarget) {
      this.videoListTarget.classList.remove('hidden');
      this.updateList();
    } else {
      console.error('Missing videoListTarget');
    }
  }

  moveUp (event) {
    const $element = $(event.target);
    const $li = $element.closest('li');
    $li.prev().insertAfter($li);
    this.updateOrders();
  }

  moveDown (event) {
    const $element = $(event.target);
    const $li = $element.closest('li');
    $li.insertAfter($li.next());
    this.updateOrders();
  }

  addVideo (event) {
    const $element = $(event.currentTarget);
    const $li = $element.closest('li');
    const videoId = $li.data('video-id');
    $li.addClass('hidden');

    const selector = `li[data-video-id='${videoId}']`
    const $videoListLi = $(this.videoOrderListTarget).find(selector);
    $videoListLi.removeClass('hidden');
    this.updateOrders();
    this.updateList();
  }

  removeVideo (event) {
    const $element = $(event.currentTarget);
    const $li = $element.closest('li');
    const videoId = $li.data('video-id');
    $li.addClass('hidden');

    const selector = `li[data-video-id='${videoId}']`
    const $videoListLi = $(this.videoListTarget).find(selector);
    $videoListLi.removeClass('hidden');
    this.updateOrders();
  }

  updateOrders () {
    const $lis = $(this.videoOrderListTarget).find('li');
    let ct = 0;
    $lis.each((idx, li) => {
      const $li = $(li);
      const $input = $li.find("input[type='hidden']");
      if ($li.hasClass('hidden')) {
        $input.val('');
      } else {
        ct += 1;
        $input.val(ct);
      }
    });

    const showingCt = $lis.toArray().filter(el => !$(el).hasClass('hidden')).length;
    if (showingCt === 0) {
      $(this.videoOrderListTarget).addClass('hidden');
    } else {
      $(this.videoOrderListTarget).removeClass('hidden');
    }
  }

  updateList () {
    const showingCt = $(this.videoListTarget).find('li').toArray().filter(el => !$(el).hasClass('hidden')).length;
    if (showingCt === 0) {
      $(this.videoListTarget).find('p').removeClass('hidden');
    } else {
      $(this.videoListTarget).find('p').addClass('hidden');
    }
  }

  clickOutside (event) {
    console.log('debug clickOutside');
    // example to close a modal
    if (this.videoListTarget) {
      this.videoListTarget.classList.add('hidden');
    } else {
      console.error('Missing videoListTarget');
    }
  }
}
