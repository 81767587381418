// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "css/application.scss"

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import { setup as mobileNavSetup } from '../mobile_nav';
import { setup as shareMenuSetup } from '../share_menu';
import { setup as copyTargetSetup } from '../copy_target';
import { setup as copyTargetFlashSetup } from '../copy_target_flash';
import { setup as modalSetup } from '../modals';
import { setup as selfiePlayerSetup } from '../selfie_player';
import { setup as submitOnChange } from '../submit_on_change';
import '../upload_progress';
import '../flash';

const application = Application.start();
const context = require.context("../controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

Rails.start()
ActiveStorage.start()

document.addEventListener('turbo:load', () => {
  mobileNavSetup();
  shareMenuSetup();
  copyTargetSetup();
  copyTargetFlashSetup();
  modalSetup();
  selfiePlayerSetup();
  submitOnChange();
});
