import $ from 'jquery';

export function setup () {
  const triggers = document.querySelectorAll('[js-open-modal-trigger]');

  triggers.forEach((trigger) => {
    trigger.addEventListener('click', (evt) => {
      const target = trigger.getAttribute('js-open-modal-trigger');
      const targetEl = document.querySelector(`[js-modal=${target}]`);
      if (targetEl) {
        targetEl.classList.remove('hidden');
      } else {
        console.error(`Have trigger for ${target} but no matching targetElement`);
      }
    });
  });

  $(document).on('click', '[js-modal-close]', (evt) => {
    $(evt.currentTarget).closest('[js-modal]').addClass('hidden');
  });
}
