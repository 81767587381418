import copy from 'copy-to-clipboard';

export function setup () {
  const copyEl = document.querySelector('[js-copy-target-flash]');

  copyEl && copyEl.addEventListener('click', (evt) => {
    evt.preventDefault();
    const content = copyEl.getAttribute('js-copy-target-flash');
    copy(content);
    Flash.notice('Copied to clipboard');
  });
}
