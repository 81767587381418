window.Flash = window.Flash || {
  notice: function (text) {
    this.show('notice', text);
  },
  error: function (text) {
    this.show('error', text);
  },
  hide: function () {
    const container = document.querySelector('[js-flash]');
    container.classList.add('hidden');
  },
  show: function (type, text) {
    const container = document.querySelector('[js-flash]');
    const message = document.querySelector('[js-flash-message]');
    const textEl = document.querySelector('[js-flash-text]');
    const closeEl = document.querySelector('[js-flash-dismiss]');
    container.classList.remove('hidden');
    closeEl.addEventListener('click', Flash.hide);
    setTimeout(() => {
      Flash.hide();
    }, 3000);
    message.classList.add(type);
    textEl.innerHTML = text;
  }
}
