import Hls from 'hls.js';

export function setup () {
  const videos = document.querySelectorAll('[js-selfie-player]');

  for (let video of videos) {
    const playbackId = video.getAttribute('js-selfie-player');
    const hlsUrl = `https://stream.mux.com/${playbackId}.m3u8`;
    const wrapper = video.parentElement;
    const playBtn = wrapper.querySelector('img');

    if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = hlsUrl;
    } else {
      const hls = new Hls();
      hls.loadSource(hlsUrl);
      hls.attachMedia(video);
    }

    video.addEventListener('click', (evt) => {
      if (video.paused) {
        video.play().catch((error) => console.error('Error calling play', error));
      } else {
        video.pause()
      }
    });

    const toggleButton = (evt) => {
      if (video.paused) {
        playBtn.classList.remove('hidden');
      } else {
        playBtn.classList.add('hidden');
      }
    }

    video.addEventListener('play', toggleButton);
    video.addEventListener('pause', toggleButton);
  }
}
