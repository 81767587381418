export function setup () {
  const navOpen = document.querySelector('[js-mobile-menu-open]');
  const navClose = document.querySelector('[js-mobile-menu-close]');
  const mobileMenu = document.querySelector('[js-mobile-menu]');

  navOpen && navOpen.addEventListener('click', () => {
    mobileMenu.classList.remove('hidden');
  });

  navClose && navClose.addEventListener('click', () => {
    mobileMenu.classList.add('hidden');
  });
}
