import copy from 'copy-to-clipboard';

export function setup () {
  const copyEl = document.querySelector('[js-copy-target]');

  copyEl && copyEl.addEventListener('click', (evt) => {
    const content = copyEl.getAttribute('js-copy-target');
    copy(content);
    copyEl.__savedHtml = copyEl.__savedHtml || copyEl.innerHTML;
    copyEl.innerHTML = 'copied to clipboard';
    setTimeout(() => {
      copyEl.innerHTML = copyEl.__savedHtml;
    }, 2000);
  });
}
