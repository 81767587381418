import copy from 'copy-to-clipboard';

const anchorStyles =
  'box-sizing: inherit; padding: inherit; margin: inherit; cursor: pointer; display: inline-block; font-family: inherit; font-size: inherit; text-decoration-line: none; background-color: transparent;';

const gifWrapperStyles =
  'box-sizing: border-box; padding: 0px; margin: 0px; font-family: Larsseit, &quot;Inter UI&quot;, &quot;Helvetica Neue&quot;, Arial, sans-serif; font-size: 10px; background-image: url(&quot;//culturecast.co/loading.gif&quot;); background-position: 50% 50%; background-repeat: no-repeat; background-size: 100px; cursor: pointer; display: table;';

function getHtmlEmailEmbed({
  viewVideoLink,
  viewJobLink,
  gifUrl
}) {
  const watchVideo = `
<a href="${viewVideoLink}" style="${anchorStyles}">Watch Video</a>
  `.trim();

  const viewJob = viewJobLink
    ? `
<a href="${viewJobLink}" style="${anchorStyles}">View Job</a>
  `.trim()
    : '';

  const spacer = viewJobLink
    ? `
<span style="box-sizing: inherit; margin: inherit; display: inline-block; font-family: inherit; font-size: inherit; text-decoration-line: none; background-color: transparent; padding-left: 5px; padding-right: 5px;">&#8226;</span>
`.trim()
    : '';

  return `
    <a href="${viewVideoLink}" style="${gifWrapperStyles}"><img src="${gifUrl}" alt="Animated thumbnail for video" style="box-sizing: border-box; padding: 0px; margin: 0px; width: auto; height: 207px;"></a><span style="box-sizing: inherit; padding: inherit; margin: inherit; color: rgb(0, 0, 0); font-family: inherit; font-size: inherit; cursor: default; display: inline-block; background-color: transparent;">${watchVideo}<span>${spacer}</span>${viewJob}</span><br>
  `;
}

export default function shareViaEmail ({ viewVideoLink, viewJobLink, gifUrl }) {
  const html = getHtmlEmailEmbed({
    viewVideoLink,
    viewJobLink,
    gifUrl,
  });

  // Create container for the HTML
  // [1]
  const container = document.createElement('div');
  container.innerHTML = html;

  // Hide element
  // [2]
  container.style.position = 'fixed';
  container.style.pointerEvents = 'none';
  container.style.opacity = 0;

  // Detect all style sheets of the page
  const activeSheets = Array.prototype.slice
    .call(document.styleSheets)
    .filter(function (sheet) {
      return !sheet.disabled;
    });

  // Mount the container to the DOM to make `contentWindow` available
  // [3]
  document.body.appendChild(container);

  // Copy to clipboard
  // [4]
  window.getSelection().removeAllRanges();

  const range = document.createRange();
  range.selectNode(container);
  window.getSelection().addRange(range);

  // [5.1]
  document.execCommand('copy');

  // [5.2]
  for (let i = 0; i < activeSheets.length; i++) {
    activeSheets[i].disabled = true;
  }

  // [5.3]
  document.execCommand('copy');

  // [5.4]
  for (let i = 0; i < activeSheets.length; i++) {
    activeSheets[i].disabled = false;
  }

  // Remove the container
  // [6]
  document.body.removeChild(container);
}
