import $ from 'jquery'
import copy from 'copy-to-clipboard'

export function setup () {
  const open = $('[js-show-share-menu]');
  const shareMenu = $('[js-share-menu]');

  open.on('click', () => {
    shareMenu.removeClass('hidden');
  });

  shareMenu.on('click', '[js-copy-link]', (evt) => {
    const target = $(evt.currentTarget);
    const link = target.attr('js-copy-link');
    console.log('click on js-copy-link', link);
    copy(link);
    Flash.notice('Copied');
  });

  document.addEventListener('click', (evt) => {
    const target = $(evt.target);
    if (open[0] == target[0]) {
      return;
    }
    if (!$.contains(shareMenu, target)) {
      shareMenu.addClass('hidden');
    }
  });
}
