import { Controller } from "stimulus";
import shareViaEmail from "../share_via_email";
import copy from 'copy-to-clipboard';

const CAROUSEL_SCROLL_W = 250;

export default class extends Controller {
  // static targets = [ "jobLinkInput", "jobLinkPreview", "jobMessageInput", "recruiterPreview", "jobMessagePreview", "jobMessageWordCt", "shareUrl", "shareEmail", "embedCode" ];
  static targets = [ "jobLinkInput", "jobMessageInput", "jobMessageWordCt", "shareUrl", "shareEmail", "embedCode", "carouselWrapper" ];

  connect() {
    this.attrs = JSON.parse(this.element.getAttribute('data-attrs'));
    this.updatePreview();
  }

  queryParams () {
    const hrefVal = this.jobLinkInputTarget.value;
    const messageVal = this.jobMessageInputTarget.value;
    let cta;

    if (hrefVal && hrefVal.startsWith('http')) {
      cta = hrefVal;
    }

    return {
      cta,
      message: messageVal,
      r_id: this.attrs.recruiterId,
    };
  }

  shareLink () {
    const params = this.queryParams();
    const { shareUrl } = this.attrs;
    const url = new URL(shareUrl);
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        url.searchParams.set(key, params[key])
      }
    });
    return url.href;
  }

  embedLink () {
    const { shareUrl } = this.attrs;
    const url = new URL(shareUrl);
    url.pathname = `${url.pathname}/embed`;
    return url.href;
  }

  copyLink () {
    const link = this.shareLink();
    copy(link);
    Flash.notice('Share URL copied to clipboard');
  }

  copyEmailEmbed () {
    shareViaEmail({
      viewVideoLink: this.shareLink(),
      viewJobLink: this.queryParams().cta,
      gifUrl: this.attrs.gifUrl,
    });
    Flash.notice('Copied to clipboard');
  }

  copyIframeCode (evt) {
    const copyEl = evt.target;
    copy(this.iframeCode());
    copyEl.__savedHtml = copyEl.__savedHtml || copyEl.innerHTML;
    copyEl.innerHTML = 'copied to clipboard';
    setTimeout(() => {
      copyEl.innerHTML = copyEl.__savedHtml;
    }, 2000);
  }

  validateMessageLength (evt) {
    this.jobMessageWordCtTarget.innerText = `${evt.target.value.length}/200`;
    if (evt.target.value.length > 200) {
      this.jobMessageWordCtTarget.classList.add('text-red-400');
    } else {
      this.jobMessageWordCtTarget.classList.remove('text-red-400');
    }
  }

  iframeCode () {
    const iframeCode = `<iframe height="600" width="300" src="${this.embedLink()}" frameborder="0" marginheight="0" marginwidth="0" allowtransparency="true"></iframe>`;
    return iframeCode;
  }

  scrollLeft () {
    if (this.carouselWrapperTarget) {
      this.carouselWrapperTarget.scrollBy({ left: -CAROUSEL_SCROLL_W, behavior: 'smooth' })
    }
  }

  scrollRight () {
    if (this.carouselWrapperTarget) {
      this.carouselWrapperTarget.scrollBy({ left: CAROUSEL_SCROLL_W, behavior: 'smooth' })
    }
  }

  updatePreview () {
    /*
    const params = this.queryParams();

    if (params.message) {
      this.jobMessagePreviewTarget.innerText = params.message;
      this.recruiterPreviewTargets.forEach((el) => el.classList.remove('hidden'));
    } else {
      this.jobMessagePreviewTarget.innerText = '';
      this.recruiterPreviewTargets.forEach((el) => el.classList.add('hidden'));
    }

    if (params.cta) {
      this.jobLinkPreviewTarget.href = params.cta;
      this.jobLinkPreviewTarget.parentElement.classList.remove('hidden');
    } else {
      this.jobLinkPreviewTarget.parentElement.classList.add('hidden');
    }
    */

    this.embedCodeTarget.innerText = this.iframeCode();
  }
}
