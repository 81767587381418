import { Controller } from "stimulus";
import { INPUT_ERROR_CSS, INPUT_VALID_CSS } from '../style_vars.js';
import $ from 'jquery';

export default class extends Controller {
  connect () {
    this.$input = $(this.element).find('input');
    this.$errorMessage = $(this.element).find('[data-error-message');

    this.$input.on('keyup', () => this.validate());
    const maxLen = this.$input.attr('data-max-len');

    this.rules = {
      maxLen: maxLen ? +maxLen : undefined,
    };
    this.validate();
  }

  validate () {
    const val = this.$input.val();
    const { maxLen } = this.rules;
    let invalid;

    if (maxLen && val && (val.length > maxLen)) {
      invalid = true;
    }

    if (invalid) {
      this.$input.removeClass(INPUT_VALID_CSS);
      this.$input.addClass(INPUT_ERROR_CSS);
      this.$errorMessage.removeClass('hidden');
    } else {
      this.$input.removeClass(INPUT_ERROR_CSS);
      this.$input.addClass(INPUT_VALID_CSS);
      this.$errorMessage.addClass('hidden');
    }
  }
}
